import mixpanel from 'lib/analytics/mixpanel';
import { motion } from 'motion/react';

import getImageFromSet from 'lib/get-image-from-set';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';

import ShimmerImage from 'components/@tedui/Atoms/ShimmerImage';
import { Link } from 'components/router';

import { SpotlightUnit, Video, type PhotoSize } from 'api';
import { UpdatedVideo } from 'components/PRISMIC/slices/FeaturedSpotlight';
import type { HeroSliderProps } from './HeroSlider.props';
import { useFeatureFlags } from 'lib/vwo/store';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

type HeroSliderSlideProps = {
  slide: HeroSliderProps['slides'][0];
  isActive: boolean;
  position: number;
  onClick?: () => void;
};

export function HeroSliderSlide({
  slide,
  isActive,
  position,
  onClick
}: HeroSliderSlideProps): React.ReactNode {
  const isMobile = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const isConferenceSpotlightEnabled = useFeatureFlags(
    state => state.isConferenceSpotlightEnable
  );

  const featuredImage =
    (slide as UpdatedVideo).overrideImageSet?.url ??
    ((slide as Video).primaryImageSet
      ? getImageFromSet(
          (slide as Video).primaryImageSet as PhotoSize[],
          isMobile ? '4x3' : '16x9'
        )
      : (slide as SpotlightUnit).imageUrl);

  const { src, blurredSrc, setIsLoading } = useOptimizedImage({
    imageUrl: featuredImage,
    width: 960,
    height: 540
  });
  const talkSlug = (slide as SpotlightUnit).url
    ? (slide as SpotlightUnit).url
    : (slide as Video).slug;

  const linkUrl = isConferenceSpotlightEnabled
    ? talkSlug
    : `/talks/${talkSlug}`;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{
        opacity: isActive ? 1 : 0,
        scale: isActive ? 1 : 0.95
      }}
      transition={{ duration: 0.5 }}
      className="aspect-h-9 relative size-full"
    >
      <Link
        href={linkUrl}
        onClick={() => {
          onClick?.();
          mixpanel.track('homepage_click', {
            current_url: window.location.href,
            title: 'editorial-section',
            item_number: String(position),
            link_type: 'image',
            value: ''
          });
        }}
        isZenithLink
        className="block size-full"
      >
        <ShimmerImage
          alt={slide.title}
          src={src || blurredSrc}
          fill
          className="object-cover object-top"
          onLoad={() => setIsLoading(false)}
          loading="eager"
        />
      </Link>
    </motion.div>
  );
}
