import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Thumbs
} from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Text } from 'components/@tedui';
import { NavigationButton } from 'components/@tedui/Organisms/Ribbon/NavigationButton';
import { Link } from 'components/router';

import SkeletonImage from 'components/skeleton-loader/image';
import Title from 'components/skeleton-loader/title';
import { trackVWOCustomConversionById } from 'lib/analytics/vwo';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

import AnimatedIconButton from 'components/AnimatedIconButton';
import { useAuthenticated } from 'lib/auth';
import type { HeroSliderProps } from './HeroSlider.props';
import { HeroSliderSlide } from './Slide';

import mixpanel from 'lib/analytics/mixpanel';
import { useFeatureFlags } from 'lib/vwo/store';

SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade, Autoplay]);

export type LINK_TYPE = 'topic' | 'title' | 'image' | 'overflow' | 'button';

export function HeroSlider({
  slides,
  id,
  isLoading = false
}: HeroSliderProps): React.ReactNode {
  const [mainSwiper, setMainSwiper] = useState<SwiperCore>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>(null);

  const mainSwiperRef = useRef(null);
  const navigationPrevRef = useRef<HTMLInputElement>(null);
  const navigationNextRef = useRef<HTMLInputElement>(null);
  const loggedIn = useAuthenticated();

  const isConferenceSpotlightEnabled = useFeatureFlags(
    state => state.isConferenceSpotlightEnable
  );

  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const swiperSlideStyle = useMemo(
    () =>
      'bg-pureBlack px-6 md-tui:!translate-x-0 md-tui:!opacity-100 relative',
    []
  );

  const slideThumbLinkStyle = useMemo(
    () => 'flex h-full flex-col gap-2 py-5 lg-tui:justify-center lg-tui:py-0',
    []
  );

  const handleThumbHover = index => {
    mainSwiperRef.current.swiper.slideToLoop(index);
  };

  const handleSwiperAutoPlay = (value: boolean) => {
    if (!mainSwiper || !thumbsSwiper) {
      return;
    }

    if (value) {
      mainSwiper.autoplay.stop();
      thumbsSwiper.autoplay.stop();
    }

    if (!value) {
      mainSwiper.autoplay.start();
      thumbsSwiper.autoplay.start();
    }
  };

  useEffect(() => {
    const updateSwiperOnResize = () => {
      if (!mainSwiper || !thumbsSwiper) {
        return;
      }
      let newSlidesPerView;
      if (window.innerWidth >= 1024) {
        newSlidesPerView = 4;
      } else if (window.innerWidth >= 768) {
        newSlidesPerView = 1;
      } else {
        newSlidesPerView = 1;
      }

      if (thumbsSwiper && thumbsSwiper.params) {
        thumbsSwiper.params.slidesPerView = newSlidesPerView;
        thumbsSwiper.update();
      }

      if (mainSwiper?.params?.slidesPerView !== 1) {
        mainSwiper.params.slidesPerView = 1;
        mainSwiper.update();
      }
    };

    window.addEventListener('resize', updateSwiperOnResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateSwiperOnResize);
    };
  }, [thumbsSwiper, mainSwiper]);

  const handleHeroClick = () => {
    trackVWOCustomConversionById(221);
  };

  const handleOnSwiper = useCallback((swiper: SwiperCore) => {
    setMainSwiper(swiper);
    resetSwiper(swiper);
  }, []);

  const handleBottomSwiper = useCallback((swiper: SwiperCore) => {
    setThumbsSwiper(swiper);
    resetSwiper(swiper);
  }, []);

  const resetSwiper = useCallback((swiper: SwiperCore) => {
    setTimeout(() => {
      if (!swiper) return;
      // Re-init navigation
      swiper.navigation.destroy();
      swiper.pagination.destroy();

      swiper.navigation.init();
      swiper.pagination.init();

      swiper.navigation.update();
      swiper.pagination.update();
    }, 300);
  }, []);

  const handleSlideChange = useCallback(
    (swiper: SwiperCore) => {
      if (!mainSwiper || !swiper) {
        return;
      }
      if (
        (mainSwiper.activeIndex === 5 && swiper.activeIndex !== 0) ||
        (mainSwiper.activeIndex < 5 &&
          swiper.activeIndex !== mainSwiper.activeIndex - 1)
      ) {
        mainSwiper.slideTo(swiper.activeIndex + 1);
      }
    },
    [mainSwiper]
  );

  const trackClick = (linkType: LINK_TYPE, itemNumber: number) => {
    mixpanel.track('homepage_click', {
      current_url: window.location.href,
      title: 'editorial-section',
      item_number: String(itemNumber),
      link_type: linkType,
      value: ''
    });
  };

  return (
    <section
      id={id}
      data-testid="featured-hero-spotlight"
      className="mx-auto min-h-96 w-full bg-pureBlack"
    >
      {isLoading ? (
        <SkeletonImage className="swiper-container mx-auto h-96 w-full max-w-7xl" />
      ) : (
        <Swiper
          // @ts-expect-error - Swiper types are not up to date
          ref={mainSwiperRef}
          effect={isMobileWidth ? 'slide' : 'fade'}
          fadeEffect={{
            crossFade: true
          }}
          loop
          slidesPerView={1}
          autoplay={{
            delay: 4000
          }}
          onClick={handleHeroClick}
          onSwiper={handleOnSwiper}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current
          }}
          pagination={{
            clickable: true,
            el: `#${id}-pagination .${id}-swiper-pagination`,
            bulletClass: 'cursor-pointer swiper-pagination-bullet',
            bulletActiveClass: 'swiper-active-bullet !bg-white'
          }}
          thumbs={{ swiper: thumbsSwiper }}
          className="swiper-container relative mx-auto h-96 w-full max-w-7xl"
          id="hero-slider-images"
        >
          <span
            slot="container-start"
            className="absolute left-0 top-0 z-20 h-full w-24 bg-opacity-50 bg-gradient-to-r from-black/50 via-black/50 to-transparent md-tui:w-48"
          />
          <span
            slot="container-end"
            className="absolute right-0 top-0 z-20 h-full w-24 bg-opacity-50 bg-gradient-to-l from-black/50 via-black/50 to-transparent md-tui:w-48"
          />

          {slides.map(
            (slide, index) =>
              slide && (
                <SwiperSlide key={slide.url ?? slide.slug} className="w-full">
                  {({ isActive }) => (
                    <HeroSliderSlide
                      onClick={handleHeroClick}
                      slide={slide}
                      position={index + 1}
                      isActive={isActive}
                    />
                  )}
                </SwiperSlide>
              )
          )}
          <div
            id="hero-slider-navigation"
            className="absolute left-0 top-0 z-50 flex h-full min-w-min items-end justify-between px-3 pb-12 opacity-50 lg-tui:hidden"
          >
            <NavigationButton
              direction="left"
              navigationRef={navigationPrevRef}
              UNSAFE_className="!w-8 !h-8 !relative !left-[initial]"
              iconSize="large"
            />
          </div>
          <div
            id="hero-slider-navigation"
            className="absolute right-0 top-0 z-50 flex h-full min-w-min items-end justify-between px-3 pb-12 opacity-50 lg-tui:hidden"
          >
            <NavigationButton
              direction="right"
              navigationRef={navigationNextRef}
              UNSAFE_className="!w-8 !h-8 !relative !right-[initial]"
              iconSize="large"
            />
          </div>
        </Swiper>
      )}

      <Swiper
        id={`${id}-thumbs`}
        // @ts-expect-error - Swiper types are not up to date
        ref={thumbsSwiper}
        onSlideChange={handleSlideChange}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          pauseOnMouseEnter: true
        }}
        onSwiper={handleBottomSwiper}
        watchSlidesProgress
        watchSlidesVisibility
        className="mx-auto h-40 w-full max-w-7xl bg-pureBlack"
      >
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <SwiperSlide key={index} className={swiperSlideStyle}>
                <a
                  data-testid={`skeleton-thumb-${index}`}
                  className={slideThumbLinkStyle}
                >
                  <Title numberOfLines={1} />
                </a>
              </SwiperSlide>
            ))
          : slides.map((slide, index: number) => {
              const isConferenceBanner =
                isConferenceSpotlightEnabled && index === 0;

              const talkSlug = isConferenceBanner
                ? slide.slug
                : `/talks/${slide.url ? slide.url : slide.slug}`;

              const topicName = isConferenceBanner
                ? 'Conference'
                : slide.topics?.nodes[0].name;

              const topicLink = isConferenceBanner
                ? slide.canonicalUrl
                : `/talks?sort=relevance&topics%5B0%5D=${slide.topics?.nodes[0].name}`;

              return (
                <div key={`slide-swiper-${index}`}>
                  {slide && (
                    <SwiperSlide
                      key={slide.url ?? slide.slug}
                      className={swiperSlideStyle}
                      onMouseEnter={() => handleThumbHover(index)}
                    >
                      <div
                        id={`thumb-${index + 1}`}
                        className={slideThumbLinkStyle}
                      >
                        <div className="mb-2 mt-3 flex items-center justify-between">
                          <Link
                            href={topicLink}
                            isZenithLink
                            onClick={() => trackClick('topic', index + 1)}
                          >
                            <Text
                              variant="label1"
                              tag="p"
                              weight="font-semibold"
                              useNewTextStyles
                              color={{ override: '#EB0028' }}
                            >
                              {topicName}
                            </Text>
                          </Link>
                          {!isConferenceBanner && loggedIn && (
                            <div id={`thumb-${index + 1}-anchor`}>
                              <AnimatedIconButton
                                talk={slide}
                                isDarkMode
                                callback={handleSwiperAutoPlay}
                                itemNumber={String(index + 1)}
                                mixpanelTitle="editorial-section"
                              />
                            </div>
                          )}
                        </div>

                        <Link
                          href={talkSlug}
                          onClick={() => {
                            handleHeroClick();
                            trackClick('title', index + 1);
                          }}
                          isZenithLink
                        >
                          <div className="mb-2">
                            <Text
                              variant="header4"
                              tag="p"
                              isBold
                              useNewTextStyles
                              UNSAFE_className="text-white"
                            >
                              {slide.title}
                            </Text>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )}
                </div>
              );
            })}

        <div
          id={`${id}-pagination`}
          className="absolute bottom-4 z-50 mb-1 ml-1 flex h-[5px] w-full items-end justify-between px-5 lg-tui:hidden"
        >
          <div
            className={`${id}-swiper-pagination !relative flex size-full gap-4`}
          />
        </div>
      </Swiper>
    </section>
  );
}
